import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import PageIntro from "../components/pageIntro"
import Form from "../components/form"
import MenuReset from "../components/MenuReset"

const ContactPage = ({ data }) => (
  <Layout solidHeader>
    <SEO title="Contact" />
    <MenuReset />

    <PageIntro>
      <h2>Contact</h2>
    </PageIntro>

    <Content>
      <div className="row">
        <div className="col">
          <div className="content">
            <address>
              <p>
                <a
                  href="https://goo.gl/maps/Z1T9Ehexp3qiZNhG7"
                  title="address"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  429 Sayers Road, Hoppers Crossing, Victoria, 3029{" "}
                </a>
              </p>
            </address>
            <p>Phone: 0413 078 633</p>
            <p>
              Email:{" "}
              <a
                href="mailto:clinic@fullyactivated.com.au"
                title="clinic@fullyactivated.com.au"
              >
                clinic@fullyactivated.com.au
              </a>
            </p>
            <p>
              We currently share the building with Tarneit Dental Care and
              Wyndham Ultrasound. Feel free to enter through the front door and
              reception will point you in the right direction.
            </p>
            <h3>Getting here</h3>
            <p>
              We are located opposite the Wyndham Village Shopping Center on the
              Sayers Road side. We have plenty of parking available on site,
              including disable parking and access to the building.
            </p>
            <p>
              Public transport? No worries. <br />
              We have a bus stop at the front of the building. Bus line 150 will
              drop you right out our door.
            </p>

            <p>
              If you would like more information or want to book an appointment,
              use the form on the right and we will get in contact with you.
              Alternatively, you can give us a call on 0413 078 633.
            </p>
            <h3>Hours</h3>
            <ul>
              <li>Monday 8am - 7pm</li>
              <li>Tuesday 8am - 7pm</li>
              <li>Wednesday 8am - 7pm</li>
              <li>Thursday 8am - 7pm</li>
              <li>Friday 8am - 4pm</li>
              <li>Saturday 8am - 1pm</li>
            </ul>
          </div>
        </div>
        <div className="col" id="form">
          <a href={data.staticMap.mapUrl} target="_blank" rel="noreferrer">
            <Img fluid={data.staticMap.childFile.childImageSharp.fluid} />
          </a>
          <br />
          <Form />
        </div>
      </div>
    </Content>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query {
    staticMap {
      mapUrl
      childFile {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
